import React from "react";
import { css, cx } from "linaria";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

const sectionClass = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
`;

const tablContainerClass = css`
  display: flex;
  column-gap: 25px;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const rowClass = css`
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: solid 1px rgba(60, 135, 236, 0.3);
  align-items: center;

  p {
    font-size: 14px;
    text-align: center;

    @media (max-width: 700px) {
      font-size: 12px;
    }
  }
`;

const tableTitleClass = css`
  width: 15%;
  font-weight: bold;
  line-height: 32px;
  padding-top: 12px;

  @media (max-width: 950px) {
    padding-top: unset;
    margin-bottom: 10px;
    width: 100%;
  }
`;

const tableWrapperClass = css`
  width: 85%;
  display: flex;
  flex-direction: column;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const columnClass = css`
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 32px;
`;

const firstColumnClass = cx(
  columnClass,
  css`
    width: 35%;
    padding-top: 12px;
  `
);
const secondColumnClass = cx(
  columnClass,
  css`
    width: 25%;
    justify-content: center;

    @media (max-width: 550px) {
      width: 30%;
    }
  `
);
const thirdColumnClass = cx(
  columnClass,
  css`
    width: 10%;
    justify-content: center;

    @media (max-width: 550px) {
      display: none;
    }
  `
);

const fourthColumnClass = cx(
  columnClass,
  css`
    width: 30%;
    justify-content: center;
    padding-left: 5%;

    @media (max-width: 550px) {
      width: 35%;
    }
  `
);

const iconClass = css`
  width: 15px;
`;

const asteriskTextClass = css`
  margin-top: 10px;
  align-self: flex-end;
  font-size: 12px;
`;

export default function TableGroup({ sectionTitle, lineItems, asteriskText }) {
  return (
    <section className={sectionClass}>
      <div className={tablContainerClass}>
        <div className={tableTitleClass}>{sectionTitle}</div>
        <div className={tableWrapperClass}>
          {lineItems.map(row => (
            <div className={rowClass} key={row.text}>
              <div className={firstColumnClass}>{row.text}</div>
              <div className={secondColumnClass}>
                {row.rhombus == "Yes" ? (
                  <StaticImage
                    src="./img/check.png"
                    alt="checkmark"
                    class={iconClass}
                    placeholder={"blurred"}
                  />
                ) : row.rhombus == "No" ? (
                  <StaticImage
                    src="./img/no.png"
                    alt="x (not included)"
                    class={iconClass}
                    placeholder={"blurred"}
                  />
                ) : (
                  <p>{row.rhombus}</p>
                )}
              </div>
              <div className={thirdColumnClass}></div>
              <div className={fourthColumnClass}>
                {row.compare == "Yes" ? (
                  <StaticImage
                    src="./img/check.png"
                    alt="checkmark"
                    class={iconClass}
                    placeholder={"blurred"}
                  />
                ) : row.compare == "No" ? (
                  <StaticImage
                    src="./img/no.png"
                    alt="x (not included)"
                    class={iconClass}
                    placeholder={"blurred"}
                  />
                ) : row.compare == "Unknown" ? (
                  <StaticImage
                    src="./img/dash.png"
                    alt="dash (unknown)"
                    class={iconClass}
                    placeholder={"blurred"}
                  />
                ) : row.compare == "Asterisk" ? (
                  <p>*</p>
                ) : (
                  <p>{row.compare}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {asteriskText && (
        <div className={asteriskTextClass}>* {asteriskText}</div>
      )}
    </section>
  );
}

TableGroup.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  lineItems: PropTypes.array.isRequired,
  asteriskText: PropTypes.string,
};
