import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";
import rhombusIcon from "./img/logo-icon-color.png";
import PdfTableGroup from "./PdfTableGroup";
import format from "date-fns/format";

export default function ComparePdfTable({ rhombusData, compareData }) {
  const getCombinedArray = (rhombusCat, compareCat) => {
    return Object.entries(rhombusCat).map(obj1 => {
      let matchedObj = Object.entries(compareCat).filter(
        obj2 => obj2[0] === obj1[0]
      )[0];
      return { ...obj1[1], ...matchedObj[1] };
    });
  };

  return (
    <View
      style={{
        padding: "0px 35px",
      }}
    >
      <View fixed style={{ flexDirection: "row", marginTop: 21 }}>
        <View style={{ width: "55%" }}></View>
        <View
          style={{
            width: "22%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image src={rhombusIcon} style={{ height: "21px", width: "21px" }} />
        </View>
        <View
          style={{
            width: "23%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: "#2B2E30FC",
              fontSize: "10px",
              fontFamily: "Helvetica-Bold",
            }}
          >
            {compareData.title}
          </Text>
        </View>
      </View>
      <View>
        <Text
          style={{
            fontSize: 10,
            fontFamily: "Helvetica-Bold",
            color: "#2A7DE1",
            marginBottom: 13,
          }}
        >
          CAMERA OPTIONS
        </Text>
        <PdfTableGroup
          data={getCombinedArray(
            rhombusData.cameraOptions,
            compareData.cameraOptions
          )}
        />
      </View>
      <View>
        <Text
          style={{
            fontSize: 10,
            fontFamily: "Helvetica-Bold",
            color: "#2A7DE1",
            marginTop: 20,
            marginBottom: 13,
          }}
        >
          CAMERA SPECS
        </Text>
        <PdfTableGroup
          data={getCombinedArray(
            rhombusData.cameraSpecs,
            compareData.cameraSpecs
          )}
        />
      </View>
      <View>
        <Text
          style={{
            fontSize: 10,
            fontFamily: "Helvetica-Bold",
            color: "#2A7DE1",
            marginTop: 20,
            marginBottom: 13,
          }}
        >
          IOT SENSORS
        </Text>
        <PdfTableGroup
          data={getCombinedArray(
            rhombusData.iotSensors,
            compareData.iotSensors
          )}
        />
      </View>
      <View break>
        <Text
          style={{
            fontSize: 10,
            fontFamily: "Helvetica-Bold",
            color: "#2A7DE1",
            marginTop: 20,
            marginBottom: 13,
          }}
        >
          ALARM MONITORING
        </Text>
        <PdfTableGroup
          data={getCombinedArray(
            rhombusData.alarmMonitoring,
            compareData.alarmMonitoring
          )}
        />
      </View>
      <View>
        <Text
          style={{
            fontSize: 10,
            fontFamily: "Helvetica-Bold",
            color: "#2A7DE1",
            marginTop: 20,
            marginBottom: 13,
          }}
        >
          CONSOLE
        </Text>
        <PdfTableGroup
          data={getCombinedArray(rhombusData.console, compareData.console)}
        />
      </View>
      <View break>
        <Text
          style={{
            fontSize: 10,
            fontFamily: "Helvetica-Bold",
            color: "#2A7DE1",
            marginBottom: 13,
          }}
        >
          AI FEATURES
        </Text>
        <PdfTableGroup
          data={getCombinedArray(
            rhombusData.aiFeatures,
            compareData.aiFeatures
          )}
          asteriskText={compareData.aiFeatures.asterisk}
        />
      </View>
      <View>
        <Text
          style={{
            fontSize: 10,
            fontFamily: "Helvetica-Bold",
            color: "#2A7DE1",
            marginTop: 20,
            marginBottom: 13,
          }}
        >
          GENERAL
        </Text>
        <PdfTableGroup
          data={getCombinedArray(rhombusData.general, compareData.general)}
        />
      </View>
      <Text style={{ color: "#9EB1BC", fontSize: 8, marginTop: 225 }}>
        Data generated on {format(new Date(), "MMMM dd, yyyy 'at' h:m b")}
      </Text>
    </View>
  );
}
