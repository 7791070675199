import { Document, Page } from "@react-pdf/renderer";
import React from "react";
import ComparePdfHeader from "./PdfHeader";
import ComparePdfTable from "./PdfTable";

export default function ComparePdf({ rhombusData, compareData }) {
  return (
    <Document>
      <Page
        style={{
          fontFamily: "Helvetica",
        }}
      >
        <ComparePdfHeader competitorName={compareData.title} />
        <ComparePdfTable rhombusData={rhombusData} compareData={compareData} />
      </Page>
    </Document>
  );
}
