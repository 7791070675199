import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";
import rhombusLogo from "../img/rhombus-logo.png";
import podiumImage from "./img/camera-podium-min.png";

export default function ComparePdfHeader({ competitorName }) {
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "space-between",
          backgroundColor: "#FAFAFA",
          paddingLeft: 35,
        }}
      >
        <View>
          <Image
            style={{
              width: "108px",
              paddingTop: 24,
            }}
            src={rhombusLogo}
          />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              height: 97,
            }}
          >
            <Text
              style={{
                color: "#2A7DE1",
                fontSize: "19.9805px",
                fontFamily: "Helvetica-Bold",
              }}
            >
              Rhombus vs. {competitorName}
            </Text>
          </View>
        </View>
        <Image
          style={{
            width: "202px",
            objectFit: "contain",
            paddingRight: 26,
          }}
          src={podiumImage}
        />
      </View>
    </View>
  );
}
