import React from "react";
import { css } from "linaria";

import { StaticImage } from "gatsby-plugin-image";
import PageContent from "components/page/PageContent";
import RoundLink from "components/common/RoundLink";

const sectionClass = css`
  background: var(--nuetral-100);
  display: grid;
  color: white;
  height: 479px;

  @media (max-width: 700px) {
    height: 379px;
  }
`;

const contentClass = css`
  display: grid;
  grid-area: 1/1;
  place-items: center;
  position: relative;
`;

const containerClass = css`
  height: 100%;
  display: flex;
  align-items: center;
`;

const textContainer = css`
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  max-width: 495px;

  animation: fadein 2.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  p {
    margin-bottom: 35px;
  }
`;

const headerText = css`
  margin-bottom: 17px;
`;

const linkClass = css`
  align-self: flex-start;
`;

const pageContentClass = css`
  height: 100%;
`;

export default function CompareHeader() {
  return (
    <section className={sectionClass}>
      <StaticImage
        src="./img/compare-header-2000-min.png"
        alt=""
        placeholder="dominantColor"
        quality={100}
        style={{
          gridArea: "1/1",
          pointerEvents: "none",
          maxHeight: "479px",
        }}
        imgStyle={{ objectPosition: "65% bottom" }}
        layout="fullWidth"
        formats={["auto", "webp", "avif"]}
        loading="eager"
      />
      <div className={contentClass}>
        <PageContent contentClass={pageContentClass}>
          <div className={containerClass}>
            <div className={textContainer}>
              <h1 className={headerText}>
                Compare Rhombus to Other Physical Security Platforms
              </h1>
              <p>
                Rhombus provides an integrated platform with industry-leading
                performance, cybersecurity, and so much more. See why industry
                leaders choose Rhombus.
              </p>
              <RoundLink
                appearance="gradientFill"
                className={linkClass}
                to="/free-trial/"
              >
                Start Trial
              </RoundLink>
            </div>
          </div>
        </PageContent>
      </div>
    </section>
  );
}
