import { PDFDownloadLink } from "@react-pdf/renderer";
import SlideInContainer from "components/common/SlideInContainer";
import PageContent from "components/page/PageContent";
import { graphql, StaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { css, cx } from "linaria";
import React, { useEffect, useState } from "react";

import CompareFilterDropdown from "./CompareFilterDropdown";
import ComparePdf from "./pdf/ComparePdf";
import TableGroup from "./TableGroup";

const tablContainerClass = css`
  display: flex;
  justify-content: flex-end;
  column-gap: 25px;
  margin-bottom: 45px;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const titleRow = css`
  padding-bottom: 19px;
  position: absolute;
  right: 0;
  top: -17px;

  @media (max-width: 950px) {
    top: 40px;
  }
  @media (max-width: 550px) {
    top: 20px;
    column-gap: 10px;
  }

  div {
    align-items: flex-end;
  }
`;

const tableWrapperClass = css`
  width: 85%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const contentClass = css`
  padding: 75px 0px 30px 0px;
`;

const slideInClass = css`
  display: flex;
  justify-content: center;
`;

const titleClass = css`
  text-align: center;
  margin-bottom: 98px;
  display: inline-block;
  align-self: flex-start;
  background-image: linear-gradient(to left, var(--teal-500), var(--blue-500));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 950px) {
    margin-bottom: 45px;
  }
`;

const rowClass = css`
  width: 98.25%;
  display: flex;
  justify-content: center;
  border-bottom: solid 1px rgba(60, 135, 236, 0.3);
  align-items: center;

  @media (max-width: 950px) {
    border-bottom: unset;
  }
`;

const columnClass = css`
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 32px;
`;

const firstColumnClass = cx(
  columnClass,
  css`
    width: 35%;
    padding-top: 12px;

    @media (max-width: 550px) {
      width: 30%;
    }
  `
);
const secondColumnClass = cx(
  columnClass,
  css`
    width: 25%;
    justify-content: center;

    @media (max-width: 550px) {
      width: 32.5%;
    }
  `
);
const thirdColumnClass = cx(
  columnClass,
  css`
    width: 10%;
    justify-content: center;

    @media (max-width: 550px) {
      display: none;
    }
  `
);

const fourthColumnClass = cx(
  columnClass,
  css`
    width: 30%;
    justify-content: flex-end;

    @media (max-width: 550px) {
      width: 37.5%;
    }
  `
);

const rhombusLogoClass = css`
  width: 156px;

  @media (max-width: 550px) {
    width: 100px;
  }
`;

const linkContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    -webkit-text-fill-color: var(--nuetral-100);
    text-decoration: none;
    font-size: 14px;
    padding: 15px 30px;
    border-radius: 10px;
    cursor: pointer;
    white-space: nowrap;
    box-sizing: border-box;
    display: block;
    color: var(--nuetral-100);
    background-image: linear-gradient(
      90deg,
      var(--blue-500),
      var(--teal-500),
      var(--blue-500),
      var(--teal-500)
    );
    background-size: 300% 100%;

    &:hover {
      text-decoration: none;
      animation: animateBg 3s linear infinite;
      @keyframes animateBg {
        0% {
          background-position: 100% 0%;
        }
        100% {
          background-position: 0% 0%;
        }
      }
    }
  }
`;

export default function CompareTable({
  compareData,
  competitors,
  title,
  filter,
  setFilter,
}) {
  const renderContent = data => {
    const rhombus = data.markdownRemark.frontmatter;

    // For PDFDownloadLink to work client-side, reference https://github.com/diegomura/react-pdf/issues/613#issuecomment-557327989
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
      setIsClient(true);
    }, []);

    const getCombinedArray = (rhombusCat, compareCat) => {
      return Object.entries(rhombusCat).map(obj1 => {
        const matchedObj = Object.entries(compareCat).filter(
          obj2 => obj2[0] === obj1[0]
        )[0];
        return { ...obj1[1], ...matchedObj[1] };
      });
    };

    return (
      <section>
        <PageContent>
          <div className={contentClass}>
            <SlideInContainer className={slideInClass}>
              <h1 className={titleClass}>
                See How Rhombus Compares to the Competition
              </h1>
            </SlideInContainer>
            <div className={tablContainerClass}>
              <div className={tableWrapperClass}>
                <div className={cx(rowClass, titleRow)}>
                  <div className={firstColumnClass} />
                  <div className={secondColumnClass}>
                    <StaticImage
                      src="./img/rhombus-logo.png"
                      alt="rhombus logo"
                      className={rhombusLogoClass}
                      placeholder="blurred"
                    />
                  </div>
                  <div className={thirdColumnClass}>vs.</div>
                  <div className={fourthColumnClass}>
                    <CompareFilterDropdown
                      options={competitors}
                      value={filter}
                      onChange={filterValue => setFilter(filterValue)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <TableGroup
              sectionTitle="Camera Options"
              lineItems={getCombinedArray(
                rhombus.cameraOptions,
                compareData.cameraOptions
              )}
            />
            <TableGroup
              sectionTitle="Camera Specs"
              lineItems={getCombinedArray(
                rhombus.cameraSpecs,
                compareData.cameraSpecs
              )}
            />
            <TableGroup
              sectionTitle="IoT Sensors"
              lineItems={getCombinedArray(
                rhombus.iotSensors,
                compareData.iotSensors
              )}
            />
            <TableGroup
              sectionTitle="Alarm Monitoring"
              lineItems={getCombinedArray(
                rhombus.alarmMonitoring,
                compareData.alarmMonitoring
              )}
            />
            <TableGroup
              sectionTitle="Console"
              lineItems={getCombinedArray(rhombus.console, compareData.console)}
            />
            <TableGroup
              sectionTitle="AI Features"
              lineItems={getCombinedArray(
                rhombus.aiFeatures,
                compareData.aiFeatures
              )}
              asteriskText={compareData.aiFeatures.asterisk}
            />
            <TableGroup
              sectionTitle="General"
              lineItems={getCombinedArray(rhombus.general, compareData.general)}
            />
          </div>
          <div className={linkContainer}>
            {isClient && (
              <PDFDownloadLink
                document={
                  <ComparePdf rhombusData={rhombus} compareData={compareData} />
                }
                fileName={`rhombus-vs-${title}.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading..." : "Download PDF"
                }
              </PDFDownloadLink>
            )}
          </div>
        </PageContent>
      </section>
    );
  };
  const GET_RHOMBUS_DATA = graphql`
    query {
      markdownRemark(frontmatter: { type: { eq: "rhombus-data" } }) {
        frontmatter {
          cameraOptions {
            dome {
              rhombus
            }
            wifiDome {
              rhombus
            }
            microDome {
              rhombus
            }
            fourK {
              rhombus
            }
            fisheye {
              rhombus
            }
            bullet {
              rhombus
            }
          }
          cameraSpecs {
            nightVision {
              rhombus
            }
            poe {
              rhombus
            }
            atRest {
              rhombus
            }
            hdWan {
              rhombus
            }
            indoorOutdoor {
              rhombus
            }
            continuousStorage {
              rhombus
            }
            onboardStorage {
              rhombus
            }
            wifi {
              rhombus
            }
            compliance {
              rhombus
            }
            ikTen {
              rhombus
            }
            warranty {
              rhombus
            }
            latency {
              rhombus
            }
            encryptedSdCards {
              rhombus
            }
          }
          iotSensors {
            doorWindow {
              rhombus
            }
            waterLeak {
              rhombus
            }
            environmental {
              rhombus
            }
            motion {
              rhombus
            }
            audio {
              rhombus
            }
            deployment {
              rhombus
            }
          }
          alarmMonitoring {
            liveMonitor {
              rhombus
            }
            audioDeter {
              rhombus
            }
            emergency {
              rhombus
            }
          }
          console {
            timelapse {
              rhombus
            }
            stream {
              rhombus
            }
            sharpness {
              rhombus
            }
            app {
              rhombus
            }
            settings {
              rhombus
            }
            encryption {
              rhombus
            }
            manage {
              rhombus
            }
            camera {
              rhombus
            }
            remote {
              rhombus
            }
            additional {
              rhombus
            }
            privacy {
              rhombus
            }
            updates {
              rhombus
            }
            local {
              rhombus
            }
            resolution {
              rhombus
            }
            live {
              rhombus
            }
            bitrate {
              rhombus
            }
            activity {
              rhombus
            }
            floorPlan {
              rhombus
            }
            cloud {
              rhombus
            }
            role {
              rhombus
            }
            ptz {
              rhombus
            }
            alerts {
              rhombus
            }
            audits {
              rhombus
            }
            monitor {
              rhombus
            }
            storage {
              rhombus
            }
            appleTv {
              rhombus
            }
            shareVideo {
              rhombus
            }
            shareStream {
              rhombus
            }
          }
          aiFeatures {
            uniquePeople {
              rhombus
            }
            vehicleCount {
              rhombus
            }
            color {
              rhombus
            }
            deployment {
              rhombus
            }
            unusual {
              rhombus
            }
            facial {
              rhombus
            }
            mask {
              rhombus
            }
            vehicle {
              rhombus
            }
            vehicleAlert {
              rhombus
            }
            indexing {
              rhombus
            }
            heat {
              rhombus
            }
            region {
              rhombus
            }
            unidentified {
              rhombus
            }
            licensePlate {
              rhombus
            }
            motion {
              rhombus
            }
            noise {
              rhombus
            }
            edge {
              rhombus
            }
          }
          general {
            firmware {
              rhombus
            }
            engineering {
              rhombus
            }
            usaSupport {
              rhombus
            }
            support {
              rhombus
            }
          }
        }
      }
    }
  `;

  return <StaticQuery query={GET_RHOMBUS_DATA} render={renderContent} />;
}
