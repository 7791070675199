import React, { useState } from "react";
import CompareHeader from "./CompareHeader";
import CompareTable from "./CompareTable";
import CompareTrialSection from "./CompareTrialSection";
import CustomerStoriesCarousel from "components/common/Customer Stories Carousel/CustomerStoriesCarousel";
import { graphql, StaticQuery } from "gatsby";
import TrialBanner from "components/common/footer-banners/TrialBanner";

export default function ComparePage({ competitors, compareData }) {
  const renderContent = data => {
    const [filter, setFilter] = useState(compareData.frontmatter.title);

    const onFilterChange = newOption => {
      const getCurrentPage = competitors.find(competitor => {
        return competitor.name === newOption;
      });

      setFilter(newOption);
      window.location.pathname = getCurrentPage.url;
    };

    const slides = [
      {
        quote:
          "Rhombus has allowed us to remotely view any property, be alerted to specific activities, better serve our customers, actively control building access, and more.",
        cite: "Brad Minsley, Managing Director",
        company: "10 Federal",
        image: data.tenFedLogo,
      },
      {
        quote:
          "We have a system that is able to operate smoothly no matter how many cameras we add. That alone is a positive ROI.",
        cite: "Arik Levy, CEO",
        company: "Luxer One",
        image: data.luxerLogo,
      },
      {
        quote:
          "Any school district looking to upgrade thheir video surveillance should choose Rhombus. The UI, performance, and price point make perfect sense.",
        cite: "Brett Brack, Director of IT",
        company: "Nemaha School District",
        image: data.nemahaLogo,
      },
      {
        quote:
          "The quality and speed of service we get from Rhombus are incredie. It’s a wonderful partnership.",
        cite: "Roger Hart-York, IT Operations Manager",
        company: "Blake’s Lotaburger",
        image: data.blakesLogo,
      },
    ];

    return (
      <>
        <CompareHeader />
        <CompareTable
          compareData={compareData.frontmatter}
          competitors={competitors}
          title={compareData.frontmatter.title}
          filter={filter}
          setFilter={onFilterChange}
        />
        <CompareTrialSection
          title={compareData.frontmatter.compareTitle}
          paragraph={compareData.frontmatter.compareDescription}
          link={compareData.frontmatter.link}
          linkText={compareData.frontmatter.linkText}
          image={compareData.frontmatter.thumbnail}
        />
        <CustomerStoriesCarousel
          slides={slides}
          backgroundColor="white"
          title="See Why Industry Leaders Choose Rhombus"
        />
        <TrialBanner />
      </>
    );
  };
  const GET_IMAGES = graphql`
    query {
      tenFedLogo: file(
        relativePath: { eq: "components/customer-stories/logos/10-federal.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      luxerLogo: file(
        relativePath: { eq: "components/customer-stories/logos/luxer-one.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      blakesLogo: file(
        relativePath: { eq: "components/customer-stories/logos/blakes.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      nemahaLogo: file(
        relativePath: { eq: "components/customer-stories/logos/nemaha.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      headerImage: file(
        relativePath: { eq: "components/cameras/img/cameras-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
