import React from "react";
import { css } from "linaria";
import PageContent from "components/page/PageContent";
import { StaticImage } from "gatsby-plugin-image";
import RoundLink from "components/common/RoundLink";
import TextLink from "components/common/TextLink";

const contentClass = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 25px;
  margin-top: 75px;

  @media (max-width: 950px) {
    flex-direction: column-reverse;
    row-gap: 45px;
  }
`;

const textContainer = css`
  width: 50%;
  max-width: 503px;
  display: flex;
  flex-direction: column;

  @media (max-width: 950px) {
    width: 100%;
    max-width: 495px;
  }

  h1 {
    background: linear-gradient(
      270deg,
      var(--teal-500) 0%,
      var(--blue-500) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 23px;
  }
`;

const linkContainer = css`
  display: flex;
  align-items: center;
  column-gap: 57px;
  margin-top: 40px;

  @media (max-width: 425px) {
    column-gap: 15px;
  }
`;

const arrowLinkContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    text-decoration: none;
  }

  p {
    display: inline-block;
    background-image: linear-gradient(
      to left,
      var(--teal-500),
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
  }
`;

const arrowClass = css`
  height: 12px;
  padding-left: 12px;
  padding-right: 6px;
  transition: padding 0.3s ease-in-out;
  .${arrowLinkContainer}:hover & {
    padding-left: 20px;
  }
`;

const imageClass = css`
  width: 50%;
  max-width: 592px;
  content-visibility: visible;

  @media (max-width: 950px) {
    width: 100%;
    max-width: 495px;
  }
`;

export default function CompareTrialSection({
  title,
  paragraph,
  link,
  linkText,
  image,
}) {
  return (
    <section>
      <PageContent>
        <div className={contentClass}>
          <div className={textContainer}>
            <h1>{title}</h1>
            <p>{paragraph}</p>
            <div className={linkContainer}>
              <RoundLink appearance="gradientFill" to="/free-trial/">
                Start Trial
              </RoundLink>
              <TextLink
                to={`/${link}/`}
                target="_blank"
                className={arrowLinkContainer}
              >
                <p>Learn About {linkText}</p>
                <div className={arrowClass}>
                  <StaticImage
                    src="../common/img/blue-arrow.png"
                    alt="arrow"
                    style={{ width: "7px" }}
                    placeholder="blurred"
                  />
                </div>
              </TextLink>
            </div>
          </div>
          <img className={imageClass} src={image} />
        </div>
      </PageContent>
    </section>
  );
}
