import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";
import check from "../img/check.png";
import dash from "../img/dash.png";
import no from "../img/no.png";
import PropTypes from "prop-types";

export default function PdfTableGroup({ data, asteriskText }) {
  return (
    <View>
      {data.map(line => (
        <View
          fixed
          style={{
            flexDirection: "row",
            marginTop: 5,
            paddingBottom: 7,
            borderBottom: "0.25px solid #B1B1B1",
            width: "100%",
          }}
        >
          <View
            style={{
              width: "55%",
            }}
          >
            <Text
              style={{
                color: "#2B2E30FC",
                fontSize: "8px",
              }}
            >
              {line.text}
            </Text>
          </View>
          <View
            style={{
              width: "22%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {line.rhombus === "Yes" ? (
              <Image src={check} style={{ height: "5px" }} />
            ) : line.rhombus === "No" ? (
              <Image src={no} style={{ width: "7px" }} />
            ) : (
              <Text style={{ color: "#2B2E30FC", fontSize: "6px" }}>
                {line.rhombus}
              </Text>
            )}
          </View>
          <View
            style={{
              width: "23%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {line.compare === "Yes" ? (
              <Image src={check} style={{ height: "5px" }} />
            ) : line.compare === "No" ? (
              <Image src={no} style={{ width: "7px" }} />
            ) : line.compare === "Unknown" ? (
              <Image src={dash} style={{ width: "7px" }} />
            ) : line.compare === "Asterisk" ? (
              <Text style={{ color: "#2B2E30FC", fontSize: "10px" }}>*</Text>
            ) : (
              <Text style={{ color: "#2B2E30FC", fontSize: "6px" }}>
                {line.compare}
              </Text>
            )}
          </View>
        </View>
      ))}
      {asteriskText && (
        <Text
          style={{
            color: "#2B2E30FC",
            fontSize: "6px",
            textAlign: "right",
            marginTop: "5px",
          }}
        >
          * {asteriskText}
        </Text>
      )}
    </View>
  );
}

PdfTableGroup.propTypes = {
  data: PropTypes.array.isRequired,
  asteriskText: PropTypes.string,
};
